<div class="home-col home-left-col" *ngIf="assignmentData?.internalUser">
    <h1 class="page-title" >Adjuster Assignment</h1>
    <div class="grid p-fluid form-group">
        <div class="col-12 md:col-7 mt-3">
            <div>
                <label for="primaryAdjuster" class="pb-2 "><strong>Primary Adjuster Assigned</strong></label>
                <input type="text" class=" disabledInput" pInputText disabled [(ngModel)] = "assignmentData.primaryAdjusterName">                        
            </div>
        </div>
        <div class="col-12 md:col-7 mt-2">
            <div>
                <label for="reassignAdjuster" class="pb-2 "><strong>Reassign Adjuster (Optional)</strong></label>
                <p-autoComplete [(ngModel)]="selectedAdjuster" 
                    [dropdown]="true" 
                    [suggestions]="filteredAdjusters" 
                    (completeMethod)="filterAdjuster($event)" 
                    [forceSelection]="true"
                    field="label"
                    [group]="true"
                    placeholder="Type or select a name" />
            </div>
        </div> 
        <div class="col-12 mt-2">
            <span><button type="button" class="btn btn-outline-secondary mr-2" (click)="goBack()">< Discard & Back</button></span>
            <span><button type="button" class="btn btn-primary"  (click)="submitAssignment()">Save & Continue</button></span>
        </div>
    </div>               
</div>
