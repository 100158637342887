import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilterService } from 'primeng/api';
import { ClaimCommonService } from 'src/app/services/claim-common.service';
import { ContextService } from 'src/app/utils/context.service';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent {

  @BlockUI() blockUI!: NgBlockUI;

  assignmentData!: { primaryAdjusterName:string
                     ,manualAssignedAdjusterId:string
                     ,users:any[]
                     ,internalUser:boolean
                   };
  adjusterList:any;
  selectedAdjuster:any;
  filteredAdjusters:any;

  constructor(private filterService: FilterService,
    private claimCommonService: ClaimCommonService, 
    private contextService: ContextService,
    private router: Router) { }

  ngOnInit() {
    const claimId = this.contextService.claimContext.claimId;
    this.blockUI.start("Please wait...");
    this.claimCommonService.getAssignmentPageData(claimId)
      .subscribe(
        {
          next: assignmentData =>{

            this.blockUI.stop();

            //Set Internal User indicator
            this.contextService.claimContext.internalUser = assignmentData?.internalUser
            
            // If user is not internal, go to Submission
            if(assignmentData && !assignmentData.internalUser){
              this.router.navigate(['/submission']);
              return;
            }


            this.assignmentData = assignmentData;
            this.adjusterList = this.getAdjusterList();
    
            this.assignmentData.users.forEach( (user:any) =>{
              if(user.userId+'' == this.assignmentData.manualAssignedAdjusterId) {
                this.selectedAdjuster =  {label : user.fullName, value : this.assignmentData.manualAssignedAdjusterId };
              }
            });

            
           
          },
          error: error =>{
            console.log(error);
            this.blockUI.stop();
          }
        }
      );
}


  submitAssignment(){
    this.blockUI.start("Please wait...");
    this.assignmentData.manualAssignedAdjusterId = this.selectedAdjuster ? this.selectedAdjuster.value : null;
    this.claimCommonService.saveAssignmentPageData(this.assignmentData)
      .subscribe({
        next: res =>{
          this.router.navigate(['/submission']);
          this.blockUI.stop();
        },
        error: error=>{
          console.log(error);
          this.blockUI.stop();
        }
      })
  }

  goBack(){
    this.router.navigate(['/preference']);
  }

  filterAdjuster(event:any){
    let filtered: any[] = [];
    let query = event.query;

    for (let optgroup of this.adjusterList) {
      let filteredSubOptions = this.filterService.filter(optgroup.items, ['label'], query, "contains");
      if (filteredSubOptions && filteredSubOptions.length) {
        filtered.push({
              label: optgroup.label,
              value: optgroup.value,
              items: filteredSubOptions
          });
      }
  }

    this.filteredAdjusters = filtered;
  }


  getAdjusterList (){

    let distinctGroupList = Array.from(
      new Map(
        this.assignmentData.users
          .map((user:any) => [
            user.categoryCode, 
            { label: user.categoryDesc, value: user.categoryCode } 
          ] as [string, { label: string, value: string }])
          .sort((a:any, b:any) => b[0].localeCompare(a[0])) 
      ).values()
    );

    distinctGroupList.map( (group:any) => {
      group.items = 
      this.assignmentData.users
          .filter((user:any) => user.categoryCode == group.value)
          .map( (user:any) =>{
            return <any>{ label: user.fullName, value: ''+user.userId }
          })
    })

    console.log(distinctGroupList);
    return distinctGroupList;

  }
  
}
